import https from "./https-common";

const getAllLabel = (shopId) => {
  return https.get(`/labels?shopId=${shopId}`);
};

const getLabelById = (id) => {
  return https.get(`/labels/${id}`);
};

const LabelService = {
  getAllLabel,
  getLabelById,
};

export default LabelService;
