import https from "./https-common";

const getShopInfo = (shopName) => {
  return https.get(`/shopInfo?${shopName ? `shop=${shopName}` : ``}`);
};

const ShopService = {
  getShopInfo,
};

export default ShopService;
