import https from "./https-common";

const getAllBadge = (shopId) => {
  return https.get(`/badges?shopId=${shopId}`);
};

const BadgeService = {
  getAllBadge,
};

export default BadgeService;
