import { Box, Modal, Stack, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

function ModalBadge({ active, onClose, dataIndex, ...rest }) {
  const badges = useSelector((state) => state.badge.list);
  const [badge, setBadge] = useState({});
  const [condition, setCondition] = useState({});

  useEffect(() => {
    setBadge(badges[dataIndex]);
    setCondition(badges[dataIndex]?.conditions);
  }, [dataIndex]);

  const conditionApply = {
    variants: "Specific product",
    select_all: "All product",
    matching_and_variants: "Automatic",
  };

  const stockContent = {
    in_stock: "In stock",
    out_of_stock: "Out of stock",
    low_stock: "Low stock",
    stock_range: "Stock Range",
    any: "No",
  };

  const inventoryContent = {
    any: "",
    no: "Not new product",
    yes: "New product",
    date_range: "Creation date range",
  };

  const discountContent = {
    any: "",
    yes: "Sale product",
    no: "Not sale product",
  };

  return (
    <Modal open={active} onClose={onClose}>
      <Box sx={style}>
        <Typography>{`Badge: ${badge?.name}`} </Typography>
        <Box sx={{ marginTop: 2 }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2"> Position:</Typography>
              {badge?.customize_position ? (
                <></>
              ) : (
                <Typography variant="body2" color="tomato">
                  {badge?.position}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">Page:</Typography>
              {badge?.page?.map((item, index) => {
                return (
                  <MDBadge
                    key={index}
                    badgeContent={item}
                    color="success"
                    variant="gradient"
                    size="sm"
                  />
                );
              })}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">Device:</Typography>
              {badge?.device?.map((item, index) => {
                return (
                  <MDBadge
                    key={index}
                    badgeContent={item}
                    color="success"
                    variant="gradient"
                    size="sm"
                  />
                );
              })}
            </Stack>
            {badge?.language?.length > 0 && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Language:</Typography>
                <Typography variant="body2" color="tomato">
                  {badge?.language.toString()}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1}>
              <Typography variant="body2">Condition:</Typography>
              <Typography variant="body2" color="tomato">
                {conditionApply[badge?.conditions?.variant_apply]}
              </Typography>
            </Stack>
            {condition?.collections?.length > 0 && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Collections:</Typography>
                <Typography variant="body2" color="tomato">
                  {condition?.collections.toString()}
                </Typography>
              </Stack>
            )}
            {condition?.exclude_collections?.length > 0 && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Exclude collections:</Typography>
                <Typography variant="body2" color="tomato">
                  {condition?.exclude_collections.toString()}
                </Typography>
              </Stack>
            )}
            {condition?.tags?.length > 0 && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Tags:</Typography>
                <Typography variant="body2" color="tomato">
                  {condition?.tags.toString()}
                </Typography>
              </Stack>
            )}
            {condition?.is_new !== "any" && (
              <>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">New status:</Typography>
                  <Typography variant="body2" color="tomato">
                    {inventoryContent[condition?.is_new]}
                  </Typography>
                </Stack>
                {condition?.is_new === "yes" && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">New day threshold:</Typography>
                    <Typography variant="body2" color="tomato">
                      {`${condition?.new_days_threshold}`}
                    </Typography>
                  </Stack>
                )}
                {condition?.is_new === "date_range" && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">Creation date range:</Typography>
                    <Typography variant="body2" color="tomato">
                      {`${condition?.new_date_from} - ${condition?.new_date_to}`}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
            {condition?.stock_status !== "any" && (
              <>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Inventory status:</Typography>
                  <Typography variant="body2" color="tomato">
                    {stockContent[condition?.stock_status]}
                  </Typography>
                </Stack>
                {condition?.stock_status === "low_stock" && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">Low stock threshold:</Typography>
                    <Typography variant="body2" color="tomato">
                      {condition?.low_stock_threshold}
                    </Typography>
                  </Stack>
                )}
                {condition?.stock_status === "stock_range" && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">Stock range:</Typography>
                    <Typography variant="body2" color="tomato">
                      {`${condition?.stock_from} - ${condition?.stock_to}`}
                    </Typography>
                  </Stack>
                )}
              </>
            )}
            {condition?.is_on_sale !== "any" && (
              <>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Discount status:</Typography>
                  <Typography variant="body2" color="tomato">
                    {discountContent[condition?.is_on_sale]}
                  </Typography>
                </Stack>
                {condition?.is_on_sale === "yes" && (
                  <>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">Discount by:</Typography>
                      <Typography variant="body2" color="tomato">
                        {condition?.discount_by}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">Discount type:</Typography>
                      <Typography variant="body2" color="tomato">
                        {condition?.discount_type === "fixed"
                          ? `Fixed[${condition?.discount_fixed}]`
                          : `Range [${condition?.discount_from} - ${discount?.discount_to}]`}
                      </Typography>
                    </Stack>
                  </>
                )}
              </>
            )}
            {(condition?.from_price || condition?.to_price) && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Price:</Typography>
                <Typography variant="body2" color="tomato">
                  {`${condition?.from_price} - ${condition?.to_price}`}
                </Typography>
              </Stack>
            )}

            {(condition?.from_weight || condition?.to_weight) && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Weight:</Typography>
                <Typography variant="body2" color="tomato">
                  {`${condition?.from_weight} - ${condition?.to_weight}`}
                </Typography>
              </Stack>
            )}
            {condition?.exclude_tags?.length > 0 && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Exclude tags:</Typography>
                <Typography variant="body2" color="tomato">
                  {condition?.exclude_tags.toString()}
                </Typography>
              </Stack>
            )}
            {condition?.title?.length > 0 && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2">Title:</Typography>
                <Typography variant="body2" color="tomato">
                  {`${condition?.title_type} - ${condition?.title_operator} - "${condition?.title}"`}
                </Typography>
              </Stack>
            )}
            {condition?.include_variants?.length > 0 && (
              <Stack>
                <Typography variant="body2">Include variants:</Typography>
                {condition?.include_variants?.map((item, index) => {
                  return (
                    <Typography variant="body2" color="tomato" key={index}>
                      {`- ${item.title}`}
                    </Typography>
                  );
                })}
              </Stack>
            )}
            {condition?.exclude_variants?.length > 0 && (
              <Stack>
                <Typography variant="body2">Exclude variants:</Typography>
                {condition?.exclude_variants?.map((item, index) => {
                  return (
                    <Typography variant="body2" color="tomato" key={index}>
                      {`- ${item.title}`}
                    </Typography>
                  );
                })}
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

ModalBadge.defaultProps = {
  active: true,
  onClose: () => {},
  dataIndex: 0,
};

ModalBadge.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataIndex: PropTypes.number, // Add this line if you plan to use the 'data' prop
};

export default ModalBadge;
