import { IconButton, Card, Typography, Stack, Avatar, Box, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getBadge } from "../../../../redux/badge";
import ModalBadge from "../Modal/ModalBadge";

const columns = [
  { Header: "preview", accessor: "preview", width: "15%", align: "left" },
  { Header: "text", accessor: "text", width: "15%", align: "left" },
  { Header: "label", accessor: "label", width: "15%", align: "left" },
  { Header: "priority", accessor: "priority", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "start date", accessor: "startDate", align: "center" },
  { Header: "end date", accessor: "endDate", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];

const TabBadge = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shop.shopId);
  const badges = useSelector((state) => state.badge.list);
  const [active, setActive] = useState(false);
  const [badgeIndex, setBadgeIndex] = useState(0);

  useEffect(() => {
    if (shopId) {
      console.log(shopId);
      dispatch(getBadge(shopId)).then((res) => setLoading(false));
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setActive(false);
  }, []);

  const handleActiveModal = useCallback(
    (index) => () => {
      setActive(true);
      setBadgeIndex(index);
    },
    []
  );
  const rows = badges.map(
    ({ image, name, priority, status, conditions, text, design, label_type }, index) => {
      return {
        preview: (
          <div style={{ width: "100px", height: "30px", display: "flex", alignContent: "center" }}>
            <img src={image} width="auto" height={30} />
            {label_type === 1 && (
              <div
                className={`${label_type === 1 && !image ? design.shape_class : ""}`}
                style={{
                  width: "30px",
                  height: "100%",
                  backgroundColor: !image && design.shape_color,
                }}
              ></div>
            )}
          </div>
        ),
        text: (
          <Stack direction="row" spacing={2} alignItems="center">
            {label_type === 1 && <Typography variant="caption">{text}</Typography>}
          </Stack>
        ),
        label: (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{name}</Typography>
          </Stack>
        ),
        priority: (
          <Box>
            <MDBadge badgeContent={`${priority}`} color="success" variant="gradient" size="sm" />
          </Box>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={status ? "active" : "deactivate"}
              color={status ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        startDate: <Typography>{conditions.starts_at}</Typography>,
        endDate: <Typography>{conditions.ends_at}</Typography>,
        action: (
          <IconButton color="info" onClick={handleActiveModal(index)}>
            <Visibility />
          </IconButton>
        ),
      };
    }
  );
  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h6" color="whitesmoke">
          Badges
        </Typography>
      </MDBox>
      <MDBox pt={3}>
        {loading ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="info" />
          </Box>
        ) : badges.length === 0 ? (
          <Typography align="center">{`Don't have any badge`}</Typography>
        ) : (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          ></DataTable>
        )}
      </MDBox>
      <ModalBadge active={active} onClose={handleCloseModal} dataIndex={badgeIndex} />
    </Card>
  );
};

export default TabBadge;
