import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BannerService from "service/BannerService";

export const getBanner = createAsyncThunk("getBanner", async (shopId) => {
  const response = await BannerService.getAllBanner(shopId);
  return response.data;
});

export const bannerSlice = createSlice({
  name: "banners",
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanner.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export const {} = bannerSlice.actions;

export default bannerSlice.reducer;
