import { IconButton, Card, Typography, Stack, Avatar, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import { Visibility } from "@mui/icons-material";

const columns = [
  { Header: "label", accessor: "label", width: "45%", align: "left" },
  { Header: "priority", accessor: "priority", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "start date", accessor: "startDate", align: "center" },
  { Header: "end date", accessor: "endDate", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
const TabTrustBadge = () => {
  const data = [1, 2, 3, 4, 5, 6];
  const rows = data.map((e) => {
    return {
      label: (
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar></Avatar>
          <Typography>Label</Typography>
        </Stack>
      ),
      priority: (
        <Box>
          <MDBadge badgeContent="1" color="success" variant="gradient" size="sm" />
        </Box>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      startDate: <Typography></Typography>,
      endDate: <Typography></Typography>,
      action: (
        <IconButton color="info">
          <Visibility />
        </IconButton>
      ),
    };
  });
  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h6" color="whitesmoke">
          Labels
        </Typography>
      </MDBox>
      <MDBox pt={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        ></DataTable>
      </MDBox>
    </Card>
  );
};

export default TabTrustBadge;
