import { IconButton, Card, Typography, Stack, Avatar, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from "../../../../redux/banner";

const columns = [
  { Header: "preview", accessor: "preview", width: "15%", align: "left" },
  { Header: "text", accessor: "text", width: "15%", align: "left" },
  { Header: "label", accessor: "label", width: "15%", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "start date", accessor: "startDate", align: "center" },
  { Header: "end date", accessor: "endDate", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
const TabBanner = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shop.shopId);
  const banner = useSelector((state) => state.banner.list);
  const [active, setActive] = useState(false);
  const [bannerIndex, setBannerIndex] = useState(0);

  useEffect(() => {
    if (shopId) {
      dispatch(getBanner(shopId)).then((res) => setLoading(false));
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setActive(false);
  }, []);

  const handleActiveModal = useCallback(
    (index) => () => {
      setActive(true);
      setBannerIndex(index);
    },
    []
  );

  const rows = banner.map(({ background_color }, index) => {
    return {
      text: (
        <Stack direction="row" spacing={2} alignItems="center">
          {label_type === 1 && <Typography variant="caption">{text}</Typography>}
        </Stack>
      ),
      label: (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>{name}</Typography>
        </Stack>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={status ? "active" : "deactivate"}
            color={status ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      startDate: <Typography>{conditions.starts_at}</Typography>,
      endDate: <Typography>{conditions.ends_at}</Typography>,
      action: (
        <IconButton color="info" onClick={handleActiveModal(index)}>
          <Visibility />
        </IconButton>
      ),
    };
  });

  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h6" color="whitesmoke">
          Labels
        </Typography>
      </MDBox>
      <MDBox pt={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        ></DataTable>
      </MDBox>
    </Card>
  );
};

export default TabBanner;
