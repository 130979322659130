import { createSlice } from "@reduxjs/toolkit";

export const trustBadge = createSlice({
  name: "trustBadges",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = trustBadge.actions;

export default trustBadge.reducer;
