import https from "./https-common";

const getAllBanner = (shopId) => {
  return https.get(`/banners?shopId=${shopId}`);
};

const BannerService = {
  getAllBanner,
};

export default BannerService;
