// ** Reducers Imports
import shop from "./shop";
import label from "./labels";
import banner from "./banner";
import badge from "./badge";
import trustBadge from "./trustBadge";

const rootReducer = { shop, label, banner, badge, trustBadge };

export default rootReducer;
