import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BadgeService from "service/BadgeService";

export const getBadge = createAsyncThunk("getBadge", async (shopId) => {
  const response = await BadgeService.getAllBadge(shopId);
  return response.data;
});

export const badgeSlice = createSlice({
  name: "badges",
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBadge.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export const {} = badgeSlice.actions;

export default badgeSlice.reducer;
