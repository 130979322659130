import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LabelService from "service/LabelService";

export const getLabel = createAsyncThunk("getLabel", async (shopId) => {
  const response = await LabelService.getAllLabel(shopId);
  return response.data;
});

export const labelSlice = createSlice({
  name: "labels",
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLabel.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export const {} = labelSlice.actions;

export default labelSlice.reducer;
